import Vue from "vue";
import store from "../store";
import vuerouter from "vue-router";

import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import Forgot_password from "../views/Forgot.vue";
import Forgot_username from "../views/ForgotUsername.vue";

import Profile from "../views/Profile.vue";

import Home from "../views/Home.vue";
import Support from "../views/Support.vue";
import Admin from "../views/Admin/Admin.vue";
import User from "../views/Admin/User.vue";
import Role from "../views/Admin/Role.vue";

import Product from "../views/Admin/Product.vue";
import Licence from "../views/Admin/Licence.vue";
import Domain from "../views/Admin/Domain.vue";
import Package from "../views/Admin/Package.vue";
import Order from "../views/Admin/Order.vue";
import Account from "../views/Admin/Account.vue";
import Confirm from "../views/Admin/Confirm.vue";
import Channel from "../views/Admin/Channel.vue";
import Api from "../views/Admin/Api.vue";
import Html from "../views/Admin/Html.vue";
import detailOrder from "../views/Admin/Order-detail.vue";

import userLicence from "../views/User/Licence.vue";
import userProduct from "../views/User/Product.vue";
import userPackage from "../views/User/Package.vue";
import userOrder from "../views/User/Order.vue";
import userDetailOrder from "../views/User/Order-detail.vue";
import userCart from "../views/User/Cart.vue";
import userInvoice from "../views/User/Invoice.vue";
import userConfirm from "../views/User/Confirm.vue";

Vue.use(vuerouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "Login - Ruas Lisensi v1.0.5",
    },
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: {
      title: "Register - Ruas Lisensi v1.0.5",
    },
  },
  {
    path: "/forgot/password",
    name: "forgot password",
    component: Forgot_password,
    meta: {
      title: "Lupa password",
    },
  },
  {
    path: "/forgot/username",
    name: "forgot username",
    component: Forgot_username,
    meta: {
      title: "Lupa Username - Ruas Lisensi v1.0.5",
    },
  },
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      title: "Beranda - Ruas Lisensi v1.0.5",
    },
  },
  {
    path: "/support",
    name: "support",
    component: Support,
    meta: {
      title: "bantuan kami - Ruas Lisensi v1.0.5",
    },
  },
  {
    path: "/admin",
    name: "admin",
    component: Admin,
    meta: {
      auth: true,
      permision: "admin",
      title: "Admin",
    },
  },
  {
    path: "/user",
    name: "user",
    component: User,
    meta: {
      auth: true,
      permision: "admin",
      title: "Data User - Ruas Lisensi v1.0.5",
    },
  },
  {
    path: "/role",
    name: "role",
    component: Role,
    meta: {
      auth: true,
      permision: "admin",
      title: "Data Role - Ruas Lisensi v1.0.5",
    },
  },
  {
    path: "/account",
    name: "account",
    component: Account,
    meta: {
      auth: true,
      permision: "admin",
      title: "Data Account - Ruas Lisensi v1.0.5",
    },
  },
  {
    path: "/html",
    name: "html",
    component: Html,
    meta: {
      auth: true,
      permision: "admin",
      title: "Data HTML Injeksi - Ruas Lisensi v1.0.5",
    },
  },
  {
    path: "/confirm",
    name: "confirm",
    component: Confirm,
    meta: {
      auth: true,
      permision: "admin",
      title: "Data Confirm - Ruas Lisensi v1.0.5",
    },
  },
  {
    path: "/channel",
    name: "channel",
    component: Channel,
    meta: {
      auth: true,
      permision: "admin",
      title: "Data channel - Ruas Lisensi v1.0.5",
    },
  },
  {
    path: "/product",
    name: "product",
    component: Product,
    meta: {
      auth: true,
      permision: "admin",
      title: "Data Product - Ruas Lisensi v1.0.5",
    },
  },
  {
    path: "/product/:id",
    name: "product detail",
    component: Package,
    meta: {
      auth: true,
      permision: "admin",
      title: "Data Product - Ruas Lisensi v1.0.5",
    },
  },
  {
    path: "/licence",
    name: "licence",
    component: Licence,
    meta: {
      auth: true,
      permision: "admin",
      title: "Data licence - Ruas Lisensi v1.0.5",
    },
  },
  {
    path: "/domain",
    name: "domain",
    component: Domain,
    meta: {
      auth: true,
      permision: "admin",
      title: "Data domain - Ruas Lisensi v1.0.5",
    },
  },
  {
    path: "/order",
    name: "order",
    component: Order,
    meta: {
      auth: true,
      permision: "admin",
      title: "Data order - Ruas Lisensi v1.0.5",
    },
  },
  {
    path: "/api",
    name: "api",
    component: Api,
    meta: {
      auth: true,
      permision: "admin",
      title: "Data api - Ruas Lisensi v1.0.5",
    },
  },
  {
    path: "/order/:id",
    name: "order admin",
    component: detailOrder,
    meta: {
      auth: true,
      permision: "admin",
      title: "Data order - Ruas Lisensi v1.0.5",
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: {
      title: "Profile Anda - Ruas Lisensi v1.0.5",
    },
  },
  {
    path: "/user/licence",
    name: "user-licence",
    component: userLicence,
    meta: {
      title: "lisensi Anda - Ruas Lisensi v1.0.5",
    },
  },
  {
    path: "/user/product",
    name: "user-product",
    component: userProduct,
    meta: {
      title: "Produk kami - Ruas Lisensi v1.0.5",
    },
  },
  {
    path: "/user/product/:id",
    name: "detail-product",
    component: userPackage,
    meta: {
      title: "detail product - Ruas Lisensi v1.0.5",
    },
  },
  {
    path: "/user/order",
    name: "user-order",
    component: userOrder,
    meta: {
      auth: true,
      permision: "free",
      title: "Pesanan Anda - Ruas Lisensi v1.0.5",
    },
  },
  {
    path: "/user/order/:id",
    name: "detail-order",
    component: userDetailOrder,
    meta: {
      auth: true,
      permision: "free",
      title: "Pesanan Anda - Ruas Lisensi v1.0.5",
    },
  },
  {
    path: "/user/invoice/:id",
    name: "detail-invoice",
    component: userInvoice,
    meta: {
      auth: true,
      permision: "free",
      title: "Konfirmasi Anda - Ruas Lisensi v1.0.5",
    },
  },
  {
    path: "/user/konfirmasi/:id",
    name: "konfirmasi-user",
    component: userConfirm,
    meta: {
      auth: true,
      permision: "free",
      title: "Pesanan Anda - Ruas Lisensi v1.0.5",
    },
  },
  {
    path: "/user/cart",
    name: "user-cart",
    component: userCart,
    meta: {
      auth: true,
      permision: "free",
      title: "Pesanan Anda - Ruas Lisensi v1.0.5",
    },
  },
  { path: "/404", component: Home },
  { path: "*", redirect: "/404" },
];

const router = new vuerouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title;
  if (to.meta.auth == true) {
    if (to.meta.permision == "free") {
      return next();
    }
    try {
      await store.dispatch("profile");
      let role = store.state.auth.profile.data.roles;
      if (role.length > 0) {
        role.filter((role) => {
          if (role.name == to.meta.permision) {
            console.log("masuk");
            return next();
          } else {
            return next("/login");
          }
        });
      } else {
        return next("/login");
      }
    } catch (error) {
      return "/";
    }
  } else {
    return next();
  }
});

export default router;
