<template>
  <v-container fill-height>
    <v-row>
      <v-col>
        <v-layout row wrap align-center justify-center>
          <v-flex md6 sm8>
            <div class="pa-5" @keyup.enter="forgot(form)">
              <h2 class="text-center ma-5 white--text">Lupa Username</h2>

              <!-- Alert Error -->
              <v-alert dense type="error" v-if="errors.auth">
                {{ errors.auth }}
              </v-alert>

              <!-- Pilihan Metode Pengiriman -->
              <v-radio-group v-model="form.method" row>
                <v-radio label="Kirim ke Email" value="email"></v-radio>
                <v-radio label="Kirim ke WhatsApp" value="whatsapp"></v-radio>
              </v-radio-group>

              <!-- Input Email / WhatsApp -->
              <v-text-field
                dense
                outlined
                solo
                v-model="form.identifier"
                :label="
                  form.method === 'email'
                    ? 'Masukkan Email Anda'
                    : 'Masukkan Nomor WhatsApp Anda'
                "
              ></v-text-field>

              <v-alert
                dense
                type="error"
                v-for="error in errors.identifier"
                :key="error"
              >
                {{ error }}
              </v-alert>

              <!-- Tombol Konfirmasi -->
              <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                  type="submit"
                  @click="forgot(form)"
                  color="success"
                  small
                >
                  Konfirmasi
                </v-btn>
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      form: {
        method: "email", // Default ke email
        identifier: "", // Email atau nomor WhatsApp
      },
    };
  },
  computed: {
    loading() {
      return this.$store.state.auth.loading;
    },
    errors() {
      return this.$store.state.auth.profile.error;
    },
  },
  methods: {
    forgot(form) {
      if (!form.identifier) {
        this.$store.commit("setErrors", {
          identifier: ["Harap masukkan Email atau WhatsApp"],
        });
        return;
      }
      this.$store.dispatch("forgotUsername", form);
    },
  },
  mounted() {
    this.$store.commit("setErrors", {});
  },
};
</script>

<style scoped>
.relative {
  position: relative;
}
</style>
