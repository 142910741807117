<template>
  <div class="full-height">
    <v-container fluid class="pa-0 fill-height">
      <v-row class="fill-height" no-gutters>
        <v-col>
          <v-card class="fill-height full-width overflow-hidden elevation-2">
            <v-toolbar flat color="orange lighten-2">
              <v-icon class="mr-2">mdi-card</v-icon>
              <v-toolbar-title class="font-weight-light">
                Data Lisensi
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                small
                @click="(dialog = true), (edit = {})"
              >
                <v-icon color="white" class="mr-1">mdi-key</v-icon>
                Tambah Lisensi
              </v-btn>
            </v-toolbar>

            <v-card-text>
              <v-text-field
                v-model="search"
                label="Search"
                @keyup.enter="searchLicence(search)"
                @click:append="searchLicence(search)"
                append-icon="mdi-magnify"
                clearable
                dense
                outlined
              ></v-text-field>
            </v-card-text>

            <v-divider></v-divider>

            <v-simple-table dense class="px-3">
              <thead>
                <tr>
                  <th class="text-left">Nama</th>
                  <th class="text-left">Pemilik</th>
                  <th class="text-left">Nama Product</th>
                  <th class="text-left">Lisensi</th>
                  <th class="text-left">Max domain</th>
                  <th class="text-left">Channel</th>
                  <th class="text-left">Aksi</th>
                </tr>
              </thead>
              <tbody v-if="!loading">
                <tr v-for="(data, index) in data.data" :key="index">
                  <td>{{ data.user ? data.user.fullname : "" }}</td>
                  <td>{{ data.user ? data.user.name : "" }}</td>
                  <td v-if="data">
                    {{ data.product.name }} ({{ data.product.code }})
                  </td>
                  <td v-if="data">{{ data.licence }}</td>
                  <td v-if="data" class="ma-1">
                    <span
                      class="font-weight-bold"
                      style="cursor: pointer; color: blue"
                      @click="openDomainDialog(data)"
                    >
                      {{ data.max_domain }}
                    </span>
                  </td>
                  <td v-if="data">{{ data.address }}</td>
                  <td>
                    <v-icon
                      color="blue"
                      class="ma-1"
                      @click="(dialog = true), (edit = data)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      color="red"
                      class="ma-1"
                      @click="deleteLicence(data)"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

            <v-divider></v-divider>

            <v-card-actions class="justify-center">
              <v-pagination
                v-model="data.current_page"
                :length="data.last_page"
                @input="getLicence(data)"
              >
              </v-pagination>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-dialog v-model="dialogDomain" max-width="600px">
          <v-card class="overflow-hidden">
            <v-toolbar flat color="orange lighten-2">
              <v-icon class="mr-2">mdi-card</v-icon>
              <v-toolbar-title class="font-weight-light">
                Domain Terhubung
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="dialogDomain = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text>
              <v-divider></v-divider>
              <v-simple-table dense>
                <thead>
                  <tr>
                    <th class="text-left">Domain</th>
                    <th class="text-left">Aksi</th>
                  </tr>
                </thead>
                <tbody v-if="!loading">
                  <tr v-for="(data, index) in domain" :key="index">
                    <td v-if="data">{{ data.domain }}</td>
                    <td>
                      <v-icon
                        color="red"
                        class="ma-1"
                        @click="deleteDomain(data)"
                      >
                        mdi-delete
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-dialog>

        <div class="text-center">
          <v-dialog v-model="dialog" width="500">
            <v-card>
              <v-card-title class="text-h5 orange lighten-2">
                Product
              </v-card-title>
              <el-card class="pa-3">
                <v-alert
                  v-for="(error, index) in errors"
                  :key="index"
                  type="error"
                  class="mb-3"
                >
                  {{ error }}
                </v-alert>

                <el-select
                  v-model="edit.user_id"
                  placeholder="Pilih user ..."
                  filterable
                  required
                  clearable
                  style="width: 430px"
                  class="mb-3"
                >
                  <el-option
                    v-for="userItem in user"
                    :key="userItem.id"
                    :label="userItem.name"
                    :value="userItem.id"
                  ></el-option>
                </el-select>

                <el-select
                  v-model="edit.product_id"
                  placeholder="Pilih product ..."
                  filterable
                  required
                  clearable
                  style="width: 430px"
                  class="mb-3"
                >
                  <el-option
                    v-for="productItem in product"
                    :key="productItem.id"
                    :label="productItem.name"
                    :value="productItem.id"
                  ></el-option>
                </el-select>

                <el-input
                  v-model.number="edit.max_domain"
                  clearable
                  type="number"
                  placeholder="Maximal domain..."
                  style="width: 430px"
                  class="mb-3"
                ></el-input>

                <el-date-picker
                  v-model="edit.due"
                  type="date"
                  placeholder="Kadaluarsa"
                  style="width: 430px"
                  class="mb-3"
                ></el-date-picker>
              </el-card>

              <v-divider></v-divider>

              <v-card-actions>
                <v-btn color="error" small @click="dialog = false">
                  tutup
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="edit.id"
                  color="primary"
                  small
                  @click="editLicence(edit)"
                >
                  simpan lisensi
                </v-btn>
                <v-btn
                  v-if="!edit.id"
                  color="primary"
                  small
                  @click="createLicence(edit)"
                >
                  simpan lisensi
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      success: false,
      model: null,
      dialog: false,
      dialogDomain: false,
      edit: false,
      product: {},
      user: {},
      domain: [],
      search: "",
    };
  },
  computed: {
    data() {
      return this.$store.state.licence.licence.data;
    },
    errors() {
      return this.$store.state.licence.licence.error;
    },
    form() {
      return this.$store.state.licence.licence.form;
    },
    loading() {
      return this.$store.state.licence.loading;
    },
  },
  methods: {
    async getLicence(data) {
      console.log(data);
      await this.$store.dispatch("licence", data.current_page);
    },
    async searchLicence(data) {
      await this.$store.dispatch("searchLicence", data);
    },
    async editLicence(data) {
      await this.$store.dispatch("editLicence", data);
      this.getLicence(this.data.current_page);
      this.dialog = false;
    },
    async createLicence(data) {
      await this.$store.dispatch("createLicence", data);
      this.getLicence(this.data.current_page);
      this.dialog = false;
    },
    async deleteLicence(data) {
      if (confirm("Yakin Akan menghapus lisensi")) {
        await this.$store.dispatch("deleteLicence", data);
        this.getLicence(this.data.current_page);
      }
    },
    async getProduct() {
      try {
        let response = await axios.get("/api/product?all=" + true);
        if (response.status == 200) {
          this.product = response.data.product;
        }
      } catch (errors) {
        this.errors = errors.response.data.erorrs;
      }
    },
    async getUser() {
      try {
        let response = await axios.get("/api/user?all=" + true, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        if (response.status == 200) {
          this.user = response.data.user;
        }
      } catch (errors) {
        console.log(errors);
      }
    },
    async deleteDomain(data) {
      try {
        if (confirm("Yakin Akan menghapus lisensi")) {
          let response = await axios.delete("/api/domain/" + data.id);
          let domain = await axios.get(
            "/api/domain?licence_id=" + data.licence_id
          );
          if (response.status == 200) {
            this.getLicence(this.data.current_page);
            this.domain = domain.data.domain;
          }
        }
      } catch (errors) {
        console.log(errors);
      }
    },
    async openDomainDialog(data) {
      this.dialogDomain = true;
      this.domain = data.domain;
    },
  },
  mounted() {
    this.getLicence(1);
    this.getProduct();
    this.getUser();
  },
};
</script>
